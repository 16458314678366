function isValidEmail(email: string): boolean {
  const format = new RegExp(/.+@.+/);
  return format.test(email);
}

function formatMoney(num: number): string {
  const fmt = new Intl.NumberFormat('en-US', {currency: 'usd', style: 'currency', minimumFractionDigits: 2});

  return fmt.format(num);
}

function formatPhone(phone: string): string {
  let nums = phone.replace(/[^0-9]+/, '');
  if (nums.length === 11 && nums.substring(0, 1) === '1') nums = nums.substring(1);
  if (nums.length === 10) {
    phone = '(' + nums.substring(0, 3) + ') ' + nums.substring(3, 6) + '-' + nums.substring(6, 10);
  }

  return phone;
}

export {formatMoney, formatPhone, isValidEmail}
