import styles from './Registrations.module.css';
import config from '../../config/config';
import {useEffect, useState} from 'react';
import update from 'immutability-helper';
import Button, {ButtonType} from '../../components/common/Button';
import ErrorList from '../../components/common/ErrorList';
import {formatMoney, formatPhone} from '../../utils/string';
import Toggle from '../../components/form/Toggle';
import {StatusResponse} from '../../types/response';

type OrderRegistrant = {
  address: {
    street1: string
    street2: string
    city: string
    state: string
    zip: string
  },
  email: string
  id: string
  name: string
  guests: number
  sku: string
  skuDesc: string
  price: number
  phone: string
}

type Order = {
  id: string
  auth: string
  created: string
  paymentMethod: string
  registrations: OrderRegistrant[]
  status: string
}

type OrdersResponse = {
  orders: Order[]
}
export default function Registrations() {
  const [errors, setErrors] = useState<string[]>([]);
  const [orders, setOrders] = useState<Order[] | undefined>();
  const [soldOut, setSoldOut] = useState<boolean>(false);
  const [busy, setBusy] = useState<boolean>(true);
  const [showDetailAtIxes, setShowDetailAtIxes] = useState<number[]>([]);

  useEffect(() => {
    fetch(config.apiBase + '/orders', {
      cache: 'no-cache',
      headers: {
        "X-Auth": process.env.REACT_APP_ORDER_VIEW_AUTH!
      },
    }).then(response => response.json())
      .then((orderResponse: OrdersResponse) => {
        setOrders(orderResponse.orders);
        fetch(config.apiBase + '/status')
          .then(response => response.json())
          .then((response: StatusResponse) => {
            setSoldOut(response.config.soldOut);
            setBusy(false);
          });
      })
      .catch(e => {
        setErrors(['An unexpected error occurred: ' + e.message])
      });
  }, []);

  const fmt = new Intl.NumberFormat('en-US');

  function toggleRegistrantsAtOrderIx(orderIx: number) {
    const ix = showDetailAtIxes.indexOf(orderIx)
    if (ix >= 0) { // Remove order row from toggled list
      setShowDetailAtIxes(update(showDetailAtIxes, {
        $splice: [[ix, 1]]
      }));
    } else { // Add order row to toggled list
      setShowDetailAtIxes(update(showDetailAtIxes, {
        $push: [orderIx]
      }));
    }
  }

  return <div className={styles.main}>
    <ErrorList errors={errors}/>

    {orders === undefined && (
      <p>Loading</p>
    )}

    {orders !== undefined && (
      <>
        <div className="txt-large">Total Orders: {fmt.format(orders.length)}</div>
        <div className="txt-medium">Total Attendees: {fmt.format(orders.reduce((carry, current) => {
          // Add registrants + guests

          return carry + current.registrations.reduce((carry, current) => carry + current.guests, 1);
        }, 0))}
        </div>
        <div className="p">
          Sold Out <Toggle toggled={soldOut} disabled={busy} onToggle={() => {
            setSoldOut(!soldOut);
            fetch(config.apiBase + '/status', {method: 'PUT', body: JSON.stringify({soldOut: !soldOut})}).then(response => response.json())
              .then(() => {
                // Success
              })
              .catch(e => {
                console.error('Could not save sold out status: ' + e.message);
                alert('Sold out status could not be saved.  Contact support.');
                setSoldOut(soldOut);
              });
        }}/> {soldOut ? 'Yes':'No'}
        </div>
        <div className="p">
          <table className={styles.orders}>
            <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Type</th>
              <th>Price</th>
              <th>Registrants</th>
              <th># Guests</th>
            </tr>
            </thead>
            <tbody>
            {orders.map((order, orderIx) => (
              order.registrations.map((registrant, regIx) => (
                <tr key={order.id + '-' + registrant.id} className={regIx === 0 ? styles.orderRow : undefined}>
                  {regIx === 0 ? (
                    <>
                      <td>{order.id}</td>
                      <td>{order.created}</td>
                      <td>{registrant.skuDesc}</td>
                      <td>{registrant.guests + 1} @ {formatMoney(registrant.price)}</td>
                    </>
                  ) : (
                    <td colSpan={4}>&nbsp;</td>
                  )}
                  <td>
                    <Button type={ButtonType.Link} onClick={() => toggleRegistrantsAtOrderIx(orderIx)}>
                      {registrant.name}
                    </Button>
                    {showDetailAtIxes.indexOf(orderIx) >= 0 && (
                      <div>
                        {registrant.email}<br/>
                        {registrant.address.street1}<br/>
                        {registrant.address.street2 && <>{registrant.address.street2}<br/></>}
                        {registrant.address.city}, {registrant.address.state} {registrant.address.zip}<br/>
                        {formatPhone(registrant.phone)}
                      </div>
                    )}
                  </td>
                  <td style={{textAlign: 'center'}}>{registrant.guests}</td>
                </tr>
              ))
            ))}
            </tbody>
          </table>
        </div>
      </>
    )}
  </div>
}
