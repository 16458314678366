import {FunctionComponent} from 'react';
import styles from './ErrorList.module.css';

type ErrorListProps = {
  errors: string[]
};

const ErrorList: FunctionComponent<ErrorListProps> = ({errors}) => {
  if (errors.length === 0) return null;

  return (
    <div className={styles.ErrorList}>
      The following error(s) occurred:
      <ul>
        {errors.map(error => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    </div>
  );
}

export default ErrorList;
