import Product from '../types/product';
import CartState from '../types/cart-state';
import {CreateOrderRequestBody, PurchaseItem} from '@paypal/paypal-js';
import {calculateCartTotal, getProductFromCartItem} from '../utils/products';

function createPaypalOrderRequestBody(products: Product[], cart: CartState): CreateOrderRequestBody {
  const total = calculateCartTotal(products, cart);

  return {
    purchase_units: [
      {
        amount: {
          currency_code: 'USD',
          value: total.toString(),
          breakdown: {item_total: {value: total.toString(), currency_code: 'USD'}}
        },
        items: cart.map(item => {
          const product = getProductFromCartItem(products, item)
          let name = product === undefined ? 'Unknown product' : product.name;
          name += ': ' + item.registrant.name;
          name += ' (' + (item.quantity - 1) + ' guest(s))';
          const cartItem: PurchaseItem = {
            name,
            quantity: item.quantity.toString(),
            unit_amount: {
              currency_code: 'USD',
              value: (product === undefined ? 0 : product.price).toString()
            }
          };
          return cartItem;
        })
      }
    ]
  };
}

function createOrderDetailsRequestBody(products: Product[], cart: CartState, auth: string='') {
  return {
    "paymentMethod": "paypal",//data.paymentSource,
    "auth" : auth,
    "registrants": cart.filter(cartItem => {
      return getProductFromCartItem(products, cartItem) !== undefined;
    }).map(cartItem => {
      const product = getProductFromCartItem(products, cartItem)!
      return {
        "name": cartItem.registrant.name,
        "email": cartItem.registrant.email,
        "phone": cartItem.registrant.phone,
        "sku": cartItem.productId,
        "price": product.price,
        "street1": cartItem.registrant.address.street1,
        "street2": cartItem.registrant.address.street2,
        "city": cartItem.registrant.address.city,
        "state": cartItem.registrant.address.state,
        "zip": cartItem.registrant.address.zip,
        "skuDesc": product.name,
        "guests": cartItem.quantity - 1
      }
    })
  };
}

export {createOrderDetailsRequestBody, createPaypalOrderRequestBody};
