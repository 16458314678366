import {useEffect, useState} from 'react';
import styles from './Home.module.css'
import CartList from '../components/cart/CartList';
import AddRegistrant from '../components/cart/AddRegistrant';
import products from '../config/products';
import CartState from '../types/cart-state';
import CartTotal from '../components/cart/CartTotal';
import update from 'immutability-helper';
import Button, {ButtonType} from '../components/common/Button';
import PaymentButtons from '../components/cart/PaymentButtons';
import classNames from 'classnames';
import ErrorList from '../components/common/ErrorList';
import config from '../config/config';
import {StatusResponse} from '../types/response';

enum CheckoutState {
  Loading = 'loading',
  SoldOut = 'soldout',
  Shopping= 'shopping',
  CheckingOut = 'checking out',
  Complete = 'complete'
}
export default function Home() {
  const [cart, setCart] = useState<CartState>([]);
  const [showAddRegistrant, setShowAddRegistrant] = useState<boolean>(cart.length === 0);
  const [errors, setErrors] = useState<string[]>([]);
  const [checkoutState, setCheckoutState] = useState<CheckoutState>(CheckoutState.Loading);

  useEffect(() => {
    fetch(config.apiBase + '/status')
      .then(response => response.json())
      .then((response:StatusResponse)  => {
        if (response.config.soldOut) {
          setCheckoutState(CheckoutState.SoldOut);
        } else {
          setCheckoutState(CheckoutState.Shopping);
        }
      })
      .catch(() => alert('Sorry, but an unknown error occurred'));
  }, []);

  return (
    <main className={styles.main}>
      <div className={styles.page}>
        <header className="txt-center">
          <img src="/assets/txt-aztec4life.png" id="txt-aztek4life" alt="Aztecs 4 Life"/>
          <img src="/assets/txt-reunion.png" id="txt-reunion" alt="Reunion"/>
          <img src="/assets/sdsu-logo.png" id="sdsu-logo" alt="SDSU Logo" />
        </header>
        <div className={styles.pageBody}>
          <ErrorList errors={errors}/>
          <section>
            <div className="flex">
              <div className="flex-grow">
                <p className="txt-center">
                  <span className="txt-xlarge txt-red txt-bold">Friday</span><br/>
                  <span className="txt-large">April 26, 2024, 7pm-11pm</span><br/>
                  Meet, Greet, & Reunite<br/>
                  Proud Bird Restaurant
                  &quot;Aviator Room&quot;</p>
              </div>
              <div className="flex-grow">
                <p className="txt-center">
                  <span className="txt-xlarge txt-red txt-bold">Saturday</span><br/>
                  <span className="txt-large">April 27, 2024, 7pm-11pm</span><br/>
                  Dinner, Dance, & Good Times<br/>
                  Proud Bird Restaurant &quot;Tuskegee Room&quot; </p>
              </div>
            </div>

            <p className="txt-center">
              <span className="txt-bold txt-red">LOCATION</span><br/>
              <a href="https://maps.app.goo.gl/K6FnsisxDMfZYPKPA" target="_blank" rel="noreferrer"><span className="txt-medium">11022 Aviation Blvd, Los Angeles</span></a>
              {/*<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3310.216028961476!2d-118.3784185546919!3d33.93557145559422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b6c6f4973865%3A0x337aea224ec7ef31!2s11022%20Aviation%20Blvd%2C%20Los%20Angeles%2C%20CA%2090045!5e0!3m2!1sen!2sus!4v1698793634016!5m2!1sen!2sus" width="600" height="450" style={{border: 0}} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>*/}
            </p>
          </section>
          {/*<hr/>*/}
          <section id="register" className={classNames('red', 'bordered', styles.padded)}>
            <div className="p txt-center txt-bold txt-red txt-xlarge">{checkoutState === CheckoutState.SoldOut ? <>Registration Sold Out</> : <>Event Registration</>}</div>

            {checkoutState === CheckoutState.SoldOut && (
              <div className="p txt-center">
                We're sorry, but all tickets have been sold out.
              </div>
            )}
            {checkoutState === CheckoutState.Shopping && (
              <>
                <div className="p">
                  <CartList products={products} cart={cart} onUpdate={cart => {
                    setCart(cart);
                  }}/>

                  {cart.length > 0 && !showAddRegistrant && (
                    <div className="p txt-large txt-center">
                      Total: <CartTotal cart={cart}/>
                    </div>
                  )}
                </div>

                {(cart.length === 0 || showAddRegistrant) && (
                  <div className="p">
                    <AddRegistrant products={products}
                                   cancel={cart.length > 0 ? () => setShowAddRegistrant(false) : undefined}
                                   addRegistrant={(product, registrant, numGuests) => {
                                     setCart(update(cart, {
                                       $push: [
                                         {
                                           registrant,
                                           quantity: 1 + numGuests,
                                           productId: product.id
                                         }
                                       ]
                                     }));
                                     setShowAddRegistrant(false);
                                   }}/>
                  </div>
                )}

                {cart.length > 0 && !showAddRegistrant && (
                  <div className="txt-center">
                    <Button type={ButtonType.Primary} onClick={() => setCheckoutState(CheckoutState.CheckingOut)}>Check Out</Button>
                  </div>
                )}
              </>
            )}

            {checkoutState === CheckoutState.CheckingOut && (
              <PaymentButtons products={products}
                              cart={cart}
                              onSuccess={() => {
                                setCart([]);
                                setCheckoutState(CheckoutState.Complete);
                              }}
                              onErrors={(errors: string[]) => {
                                setCheckoutState(CheckoutState.Shopping);
                                setErrors(errors);
                              }}
              />
            )}

            {checkoutState === CheckoutState.Complete && (
              <div className="txt-center">
                <div className="txt-large">Thank You</div>
                <p>Your registration is complete.</p>
                <div className="p">
                  <Button type={ButtonType.Primary} onClick={() => setCheckoutState(CheckoutState.Shopping)}>
                    Start New Order
                  </Button>
                </div>
              </div>
            )}

          </section>

          {/*<hr/>*/}

          <section>
            <p className="txt-center txt-large">Hotel Information</p>
            <p className="txt-center txt-bold">Please use the code <span className="txt-red">&quot;Aztec for Life&quot;</span> when making your hotel reservations.</p>
            <p>
              A limited number of rooms have been reserved for both Friday and Saturday nights.
              The hotels are located close to our event location and are within walking distance of each other.
            </p>
            <div className="flex" style={{justifyContent: 'center', gap: 50}}>
              <div className="flex-growx">
                <strong>AC Marriott Hotel</strong><br/>
                2130 East Maple Eve<br/>
                El Segundo, CA 90245<br/>
                (310) 322-3333</div>
              <div className="flex-growx">
                <strong>Hyatt Place</strong><br/>
                750 North Nash St<br/>
                El Segundo, CA 90245<br/>
                (310) 322-2880</div>
            </div>
          </section>
          <hr/>
          <section className="txt-center">
            Questions? Email the reunion committee at:<br/>
            <a href="mailto:imanaztec4life@gmail.com">imanaztec4life@gmail.com</a>
          </section>
        </div>
      </div>
    </main>
  )
}
