type Config = {
  apiBase: string
  paypal: {
    clientId: string,
    clientToken: string
  }
}

const config: Config = {
  apiBase: process.env.REACT_APP_API_BASE!,
  paypal: {
    clientId: process.env.REACT_APP_PAYPAL_CLIENTID!,
    clientToken: process.env.REACT_APP_PAYPAL_CLIENTTOKEN!
  }
}

export default config;
