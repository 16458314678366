import {FunctionComponent} from 'react';
import TextInput from './TextInput';
import {isValidEmail} from '../../utils/string';

type EmailInputProps = {
  email: string
  onChange: (email: string) => void
};

const EmailInput: FunctionComponent<EmailInputProps> = ({email, onChange}) => {
  return (
    <TextInput value={email} onChange={onChange} valid={email.length === 0 || isValidEmail(email)}/>
  );
}

export default EmailInput;
