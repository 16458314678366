import {FunctionComponent, useState} from 'react';
import Product from '../../types/product';
import update from 'immutability-helper';
import Registrant from '../../types/registrant';
import CartState from '../../types/cart-state';
import SelectInput from '../form/SelectInput';
import {createNumGuestsOption, generateNumGuestsOptions} from '../../utils/generators';
import {formatMoney} from '../../utils/string';
import DeleteIcon from '../icons/DeleteIcon';
import Button from '../common/Button';

// type RegistrantState = {
//   ix: number | undefined
//   registrant: Registrant | undefined
//   productId: string | undefined
// }

type CartListProps = {
  products: Product[]
  cart: CartState
  onUpdate: (cart: CartState) => void
}
const CartList: FunctionComponent<CartListProps> = ({cart, onUpdate, products}) => {

  if (cart.length === 0) return null;

  return (
    <div className="CartList">
      <table className="table table--full">
        <thead>
        <tr>
          <th>Attendee</th>
          <th>Registration</th>
          <th># Guests</th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
          {cart.map((cartItem, ix) => {
            const productIx = products.findIndex(product => product.id === cartItem.productId);
            if (productIx < 0) return null; // Shouldn't happen, but...
            const product = products[productIx];
            const numGuests = cartItem.quantity - 1;
            return (
                <tr key={cartItem.productId + cartItem.quantity + cartItem.registrant.name}>
                  <td>{cartItem.registrant.name}</td>
                  <td>{product.name} @ {formatMoney(product.price)}</td>
                  <td>
                    <SelectInput value={createNumGuestsOption(numGuests)} options={generateNumGuestsOptions(5)} onChange={option => {
                      onUpdate(update(cart, {
                        [ix]: {
                          quantity: {$set: 1 + (option === undefined ? 0 : parseInt(option.id))}
                        }
                      }))
                    }}/>
                    &nbsp;@ {formatMoney(product.price)}
                  </td>
                  <td style={{textAlign: 'right'}}>
                    {formatMoney(cartItem.quantity * product.price)}
                    &nbsp;
                    <Button onClick={() => {
                      onUpdate(update(cart, {
                        $splice: [[ix, 1]]
                      }))
                    }}>
                      <DeleteIcon/>
                    </Button>
                  </td>
                </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default CartList;
