import ProductType from '../types/product-type';

const ProductTypes: {EventRegistration: ProductType} = {
  EventRegistration: {
    name: 'Event Registration',
    unitLabel: 'Person'
  }
}

export default ProductTypes;
