import {FunctionComponent} from 'react';
import './Toggle.css';
import classNames from 'classnames';

type ToggleProps = {
  toggled: boolean
  disabled: boolean
  onToggle: () => void
};

const Toggle: FunctionComponent<ToggleProps> = ({disabled, onToggle, toggled}) => {
  return (
    <div className={classNames('Toggle', {disabled, toggled})}
         onClick={() => {
           if (disabled) return;
           onToggle();
         }}
    >
      <div className={classNames('Toggler')}/>
    </div>
  );
}

export default Toggle;
