import {FunctionComponent, PropsWithChildren} from 'react';
import './Button.css';
import classNames from 'classnames';

export enum ButtonType {
  Default = 'default',
  Primary = 'primary',
  InvertedPrimary = 'primary inverted',
  // Secondary = 'secondary',
  Success = 'success',
  // Danger = 'danger',
  Link = 'link',
  DangerLink = 'link danger'
}

type ButtonProps = PropsWithChildren<{
  type?: ButtonType
  disabled?: boolean
  onClick: () => void
}>;

const Button: FunctionComponent<ButtonProps> = ({children, type, disabled, onClick}) => {
  if (type === undefined) type = ButtonType.Default;

  return (
    <div className={classNames(
      'Button',
      type,
      disabled === true ? 'disabled' : null
    )}
         onClick={() => {
           if (!disabled) onClick();
         }}
    >
      {children}
    </div>
  );
}

export default Button;
