import Product from '../types/product';
import ProductTypes from '../config/product-types';

const products: Product[] = [
  {
    id: 'only_friday',
    name: 'Only Friday Night',
    productType: ProductTypes.EventRegistration,
    description: '',
    // price: 1
    price: 75
  },
  {
    id: 'only_saturday',
    name: 'Only Saturday Night',
    productType: ProductTypes.EventRegistration,
    description: '',
    // price: 1.50
    price: 150
  },
  {
    id: 'both_nights',
    name: 'Attending Both Nights',
    productType: ProductTypes.EventRegistration,
    description: '',
    // price: 2
    price: 200
  },
];

export default products;
