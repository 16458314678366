import {FunctionComponent} from 'react';
import products from '../../config/products';
import CartState from '../../types/cart-state';
import {formatMoney} from '../../utils/string';

type CartTotalProps = {
  cart: CartState
};

const CartTotal: FunctionComponent<CartTotalProps> = ({cart}) => {
  // Calculate total price of cart
  const cartTotal = cart.reduce((prevVal, cur) => {
    const prodIx = products.findIndex(product => product.id === cur.productId);

    return prevVal + (prodIx >= 0 ? products[prodIx].price : 0) * cur.quantity;
  }, 0);

  return <>{formatMoney(cartTotal)}</>
}

export default CartTotal;
