import {FunctionComponent} from 'react';
import styles from './TextInput.module.css';
import classNames from 'classnames';

export type InputTextProps = {
  className?: string
  value: string
  onChange: (value: string) => void
  valid?: boolean
};

const TextInput: FunctionComponent<InputTextProps> = ({className, value, onChange, valid}) => {
  return (
    <div className={classNames(styles.TextInput, className, valid === false ? styles.Invalid : null)}>
      <input type="text" value={value} onChange={e => {
        onChange(e.target.value);
      }}/>
    </div>
  );
}

export default TextInput;
