import {FunctionComponent, useState} from 'react';
import config from '../../config/config';
import {PayPalButtons, PayPalScriptProvider} from '@paypal/react-paypal-js';
import CartState from '../../types/cart-state';
import Product from '../../types/product';
import {createOrderDetailsRequestBody, createPaypalOrderRequestBody} from '../../utils/checkout';

type PaymentButtonsProps = {
  products: Product[]
  cart: CartState
  onSuccess: (orderId: string) => void
  onErrors: (errors: string[]) => void
};

const PaymentButtons: FunctionComponent<PaymentButtonsProps> = ({cart, products, onErrors, onSuccess}) => {
  const [isPaymentLoaded, setIsPaymentLoaded] = useState<boolean>(true);
  return (
    <div>
      {!isPaymentLoaded && <>Loading payment options...</>}
      <PayPalScriptProvider options={{
        clientId: config.paypal.clientId
      }}>
        <PayPalButtons style={{label: 'paypal'}}
                       createOrder={(data, actions) => {
                         return actions.order.create(createPaypalOrderRequestBody(products, cart)).then(paypalOrderId => {
                           return fetch(config.apiBase + '/orders', {
                             method: 'POST',
                             cache: 'no-cache',
                             body: JSON.stringify(createOrderDetailsRequestBody(products, cart, 'paypal:' + paypalOrderId))
                           })
                             .then(response => response.json())
                             .then(result => {
                               window.localStorage.setItem('order', result.orderId);
                               window.localStorage.setItem('paypal', paypalOrderId);
                               return paypalOrderId;
                             });
                         });
                       }}
                       onInit={() => {
                         setIsPaymentLoaded(true)
                       }}
                       onApprove={(data, actions) => {
                         if (!actions.order) {
                           onErrors(['An unknown error occurred while creating your order']);
                           return Promise.resolve();
                         }

                         return actions.order.capture().then((details) => {
                           const orderId = window.localStorage.getItem('order');
                           const paypalOrderId = window.localStorage.getItem('paypal');

                           window.localStorage.removeItem('order');
                           window.localStorage.removeItem('paypal');
                           // This should not happen, but if it does, let the user know their payment was successful

                           if (orderId === null) {
                             onSuccess('');
                             return;
                           }

                           return fetch(config.apiBase + '/orders/' + orderId + '/paypal/' + paypalOrderId, {
                             method: 'PUT',
                             body: JSON.stringify({auth: data.authCode})
                           })
                             .then(() => {
                               onSuccess(orderId);
                             })
                             .catch(e => {
                               onErrors(['An unexpected error occurred.  Your order looks like it was processed correctly, but it was not recorded correctly.  We recommend contact support before trying to register again.'])
                             });
                         })
                       }}
        />
      </PayPalScriptProvider>
    </div>
  );
}

export default PaymentButtons;
