import {FunctionComponent} from 'react';
import Product from '../../types/product';
import SelectInput, {SelectOption} from './SelectInput';

type ProductSelectInputProps = {
  selectedProduct: Product | undefined
  products: Product[]
  onChange: (product: Product) => void
};

const ProductSelectInput: FunctionComponent<ProductSelectInputProps> = ({selectedProduct, products, onChange}) => {
  const fmt = new Intl.NumberFormat('en-US', {currency: 'usd', style: 'currency', minimumFractionDigits: 2});
  function product2Option(product: Product): SelectOption {
    return {
      id: product.id,
      label: product.name + ' - ' + fmt.format(product.price)
    }
  }

  return (
    <SelectInput value={selectedProduct === undefined ? undefined : product2Option(selectedProduct)}
                 options={products.map(product2Option)}
                 defaultLabel="-- Select Ticket --"
                 onChange={option => {
                   const ix = products.findIndex(product => product.id === option?.id);
                   if (ix < 0) return;
                   onChange(products[ix]);
                 }}
    />
  );
}

export default ProductSelectInput;
