import {FunctionComponent} from 'react';

const DeleteIcon: FunctionComponent = () => {
  const width = 16;
  const offset = 4;
  return (
    <svg viewBox="0 0 16 16" width={16} height={16}>
      <circle cx={8} cy={8} r={8} fill="#c00"/>
      <line x1={offset} x2={width-offset} y1={offset} y2={width-offset} stroke="#fff" strokeWidth={3}/>
      <line x1={width-offset} x2={offset} y1={0+offset} y2={width-offset} stroke="#fff" strokeWidth={3}/>
    </svg>
  );
}

export default DeleteIcon;
