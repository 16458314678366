import {FunctionComponent, PropsWithChildren} from 'react';
import styles from './ButtonSet.module.css';

const ButtonSet: FunctionComponent<PropsWithChildren> = ({children}) => {
  return (
    <div className={styles.ButtonSet}>
      {children}
    </div>
  );
}

export default ButtonSet;
