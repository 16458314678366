import Product from '../types/product';
import CartState, {CartItem} from '../types/cart-state';
import products from '../config/products';

function getProductFromCartItem(products: Product[], cartItem: CartItem): Product | undefined {
  const productIx = products.findIndex(product => product.id === cartItem.productId);
  if (productIx < 0) return undefined;

  return products[productIx];
}

function calculateCartTotal(products: Product[], cart: CartState): number {
  return cart.reduce((prevVal, cur) => {
    const prodIx = products.findIndex(product => product.id === cur.productId);

    return prevVal + (prodIx >= 0 ? products[prodIx].price : 0) * cur.quantity;
  }, 0);
}

export {calculateCartTotal, getProductFromCartItem}
