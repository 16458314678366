import {SelectOption} from '../components/form/SelectInput';

function generateNumGuestsOptions(num: number): SelectOption[] {
  const numArr: number[] = [...new Array(num+1)];
  return numArr.map((num, ix) => ({
    id: ix.toString(),
    label: ix.toString()
  }));
}

function createNumGuestsOption(num: number): SelectOption {
  return {
    id: num.toString(),
    label: num.toString(),
  }
}

export {generateNumGuestsOptions, createNumGuestsOption}
