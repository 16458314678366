import {FunctionComponent} from 'react';
import styles from './SelectInput.module.css'
import classNames from 'classnames';

type SelectOption = {
  id: string
  label: string
}
type SelectInputProps = {
  fullWidth?: boolean
  value: SelectOption | undefined
  options: SelectOption[]
  onChange: (value: SelectOption | undefined) => void
  defaultLabel?: string
};

const SelectInput: FunctionComponent<SelectInputProps> = ({fullWidth, defaultLabel, onChange, options, value}) => {
  return (
    <select className={classNames(styles.SelectInput, fullWidth !== false ? styles.SelectInputFullWidth : null)}
            onChange={ev => {
              const selVal = ev.target.options[ev.target.selectedIndex].value;
              const ix = options.findIndex(option => option.id === selVal);
              onChange(ix >= 0 ? options[ix] : undefined);
            }}
            value={value === undefined ? undefined : value.id}
    >
      {defaultLabel && <option value="">{defaultLabel}</option>}
      {options.map(option => (
        <option key={option.id} value={option.id}>{option.label}</option>
      ))}
    </select>
  );
}

export default SelectInput;
export type {SelectOption};
