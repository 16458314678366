import {FunctionComponent, useState} from 'react';
import Registrant from '../../types/registrant';
import Product from '../../types/product';
import InputGrid from '../form/InputGrid';
import TextInput from '../form/TextInput';
import update from 'immutability-helper';
import ProductSelectInput from '../form/ProductSelectInput';
import styles from './AddRegistrant.module.css';
import EmailInput from '../form/EmailInput';
import SelectInput from '../form/SelectInput';
import Button, {ButtonType} from '../common/Button';
import {isValidEmail} from '../../utils/string';
import {generateNumGuestsOptions} from '../../utils/generators';
import ButtonSet from '../common/ButtonSet';

type AddRegistrantProps = {
  products: Array<Product>
  addRegistrant: (product: Product, registrant: Registrant, numGuests: number) => void
  cancel?: () => void
};

const AddRegistrant: FunctionComponent<AddRegistrantProps> = ({addRegistrant, cancel, products}) => {
  const [product, setProduct] = useState<Product | undefined>();
  const [registrant, setRegistrant] = useState<Registrant>({
    name: '',
    address: {
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: ''
    },
    phone: '',
    email: '',
    active: true
  });
  const [numGuests, setNumGuests] = useState<number>(0);

  const fmt = new Intl.NumberFormat('en-US', {currency: 'usd', style: 'currency', minimumFractionDigits: 2});
  const isValidForm = (
    product !== undefined &&
      registrant.name.length > 0 &&
      registrant.address.street1.length > 0 &&
      registrant.address.city.length > 0 &&
      registrant.address.state.length > 0 &&
      registrant.address.zip.length > 0 &&
      isValidEmail(registrant.email)
  )

  return (
    <div className="AddRegistrant">
      <div className="txt-center">
        {!product && <div>Select reservation option: </div>}
        <div>
          {product === undefined && (
            <ProductSelectInput selectedProduct={product} products={products} onChange={product => {
              setProduct(product);
            }}/>
          )}
          {product !== undefined && (
            <div className="p">
              <span className="txt-large">{product.name + ' - ' + fmt.format(product.price)}</span>&nbsp;
              [ <Button onClick={() => setProduct(undefined)} type={ButtonType.Link}>Change</Button> ]
            </div>
          )}

          {cancel && !product && <>&nbsp;<Button type={ButtonType.Link} onClick={() => cancel()}>Cancel</Button></>}
        </div>
      </div>
      <InputGrid>
        {product !== undefined && (
          <>
            <div>Name:</div>
            <div><TextInput valid={registrant.name.length > 0} value={registrant.name} onChange={name => setRegistrant(update(registrant, {
              name: {$set: name}
            }))}/></div>

            <div>Street 1:</div>
            <div>
              <TextInput valid={registrant.address.street1.length > 0} value={registrant.address.street1} onChange={street1 => setRegistrant(update(registrant, {address: {street1: {$set: street1}}}))}/>
            </div>

            <div>Street 2:</div>
            <div>
              <TextInput value={registrant.address.street2} onChange={street2 => setRegistrant(update(registrant, {address: {street2: {$set: street2}}}))}/>
            </div>

            <div>City, State, Zip:</div>
            <div className={styles.CityStateZipRow}>
              <div>
                <TextInput valid={registrant.address.city.length > 0} value={registrant.address.city} onChange={city => setRegistrant(update(registrant, {address: {city: {$set: city}}}))}/>
                <div className={styles.FieldLabel}>City</div>
              </div>

              <div>
                <TextInput value={registrant.address.state} onChange={state => setRegistrant(update(registrant, {address: {state: {$set: state}}}))}/>
                <div className={styles.FieldLabel}>State</div>
              </div>

              <div>
                <TextInput value={registrant.address.zip} onChange={zip => setRegistrant(update(registrant, {address: {zip: {$set: zip}}}))}/>
                <div className={styles.FieldLabel}>Zip</div>
              </div>

            </div>

            <div>Email:</div>
            <div>
              <EmailInput email={registrant.email} onChange={email => setRegistrant(update(registrant, {email: {$set: email}}))}/>
            </div>

            <div>Phone: </div>
            <div>
              <TextInput value={registrant.phone} onChange={phone => setRegistrant(update(registrant, {
                phone: {$set: phone}
              }))}/>
            </div>

            <div># Guests</div>
            <div>
              <SelectInput value={{id: numGuests.toString(), label: numGuests.toString()}}
                           options={generateNumGuestsOptions(5)}
                           onChange={option => {
                             if (option === undefined) {
                               setNumGuests(0);
                               return;
                             }
                             setNumGuests(parseInt(option.id));
                           }}/>
            </div>

            <div></div>
            <div>
              Your registration
              {numGuests > 0 && (<> + {numGuests} guest(s)</>)}
              &nbsp;
              =
              &nbsp;{fmt.format(product.price + (product.price * numGuests))}<br/>
              <ButtonSet>
                <Button disabled={!isValidForm} onClick={() => {
                  addRegistrant(product, registrant, numGuests);
                  setProduct(undefined);
                  setNumGuests(0);
                  setRegistrant({
                    name: '',
                    address: {
                      street1: '',
                      street2: '',
                      city: '',
                      state: '',
                      zip: '',
                    },
                    active: true,
                    email: '',
                    phone: ''
                  })
                }} type={ButtonType.Primary}>Add to Cart</Button>
                {cancel && <Button type={ButtonType.Primary} onClick={() => cancel()}>Cancel</Button>}
                {!isValidForm && <div style={{color: 'red', alignSelf: 'center'}}>Fill out the fields above to continue.</div>}
              </ButtonSet>
            </div>
          </>
      )}
      </InputGrid>
    </div>
  );
}

export default AddRegistrant;
